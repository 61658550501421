import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import qs from 'query-string'
import { Layout } from 'components'
import SEO from 'components/seo'
import ActivationView from 'domains/AffiliateDashboard/SuccessSetNewPassword'

const AffiliateAccountSuccessullySetNewPassword= ({ location }) => {
  const queryParams = qs.parse(location.search)
  const [tokenUser, setTokenUser] = useState('')

  useEffect(() => {
    const token = queryParams.t
    setTokenUser(token)

    if (!token) navigate('/')
  }, [queryParams.t])

  return(
    <>
      <SEO title='Successfuly Set New Password Affiliate Account' />
      <Layout>
        <ActivationView 
          type='success'
          tokenUser={ tokenUser }
          queryParams={  queryParams }
        />
      </Layout>
    </>
  )
}

export default AffiliateAccountSuccessullySetNewPassword
