import { darkBlueGrey } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE } from 'utils'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  margin: auto;
  text-align: center;
  justify-content: center;
  height: calc(100vh - 8em);

  ${IS_FRAME_MOBILE} {
    width: 90%;
  }
`

export const Title = styled.div`
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  color: ${darkBlueGrey};
  font-size: 24px;

  ${IS_FRAME_MOBILE} {
    font-size: 1.5em;
    text-align: center;
  }
`

export const Desc = styled.div`
  text-align: center;
  font-size: 14px;
  width: 80%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.43px;
  margin: 24px 0;
  color: ${darkBlueGrey};

  ${IS_FRAME_MOBILE} {
    text-align: center;
    font-size: 0.9rem !important;
  }
`

export const Img = styled.img`
  display: block;
  width: 40%;

  ${IS_FRAME_MOBILE} {
    width: 60%;
  }
`

export const Gap = styled.div`
  margin-bottom: 2em;
`