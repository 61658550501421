import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useIntl } from 'react-intl'
import qs from 'query-string'
import { Button } from 'components'
import { Wrapper, Title, Desc, Img, Gap } from './styles'
import API, { AffiliateAuthURL } from 'commons/API'
import { useToasts } from 'react-toast-notifications'
import { ACTIVATION_VIEW_DATA } from './constant'
import { useAuth } from '../../../providers/auth-provider'
import QlueUnityDashboardIcon from 'media/images/affiliate-dashboard/qlueunity-dashboard-icon.png'
import { IconLockSuccess } from '@qlue/icon-kit'

const ActivationView = ({ 
  type, 
  tokenUser 
}) => {

  const { addToast } = useToasts()
  const intl = useIntl()
  const [isLoading, setLoading] = useState(false)
  const { fetchAnalytics } = useAuth()

  const handleClickLinkExpired = () => {
    setLoading(true)
    API({
      url     : AffiliateAuthURL.PostResendActivationAffiliate,
      method  : 'post',
      data    : { activation_link: location.origin + location.pathname },
      headers : {
        authorization: `Bearer ${tokenUser}`
      }
    })
      .then(() => {
        const params = qs.stringify({ modePage: 'link_was_resend', t: tokenUser })
        navigate(`${location.pathname}/?${params}`)
      })
      .catch((error) => {
        addToast(error.response.data.message, { appearance: 'warning' })
      })
      .finally(() => setLoading(false))
  }

  const handleClickSuccess = () => {
    fetchAnalytics() // kick previous session if user still login then try to register
    navigate(ACTIVATION_VIEW_DATA[type].nextLink, { state: { show_pop_up: true } })
  }

  const handleClickLinkResend = () => {
    navigate(ACTIVATION_VIEW_DATA[type].nextLink)
  }

  const handleClickBtn = ({ type }) => {
    if(type === 'link_expired') handleClickLinkExpired()
    else if(type === 'success') handleClickSuccess() 
    else handleClickLinkResend()
  
    return ''
  }

  return(
    <Wrapper>
      <Img 
        src={ QlueUnityDashboardIcon } 
        alt='QlueUnity'
      />
      <Title>
        { intl.formatMessage({ id: ACTIVATION_VIEW_DATA[type].title }) }
      </Title>
      <Desc>
        { intl.formatMessage({ id: ACTIVATION_VIEW_DATA[type].desc }) }
      </Desc>
      <IconLockSuccess />
      <Gap />
      <Button 
        isLoading={ isLoading }
        buttonType="warning"
        onClick={ () => handleClickBtn({ type }) }
      >
        { intl.formatMessage({ id: ACTIVATION_VIEW_DATA[type].btnText }) }
      </Button>
    </Wrapper>
  )
}

export default ActivationView
