export const ACTIVATION_VIEW_DATA = {
  success: {
    title    : 'Successfully Set New Password',
    desc     : 'Your password has been succesfully changed.',
    btnText  : 'GO BACK TO LOGIN PAGE',
    nextLink : '/affiliate-dashboard/login'
  },
  link_expired: {
    title   : 'RESET PASSWORD LINK HAS EXPIRED',
    desc    : 'Your reset password link has already expired. Kindly click the button below to resend reset password link to your email',
    btnText : 'RESEND SET PASSWORD LINK'
  },
  link_was_resend: {
    title    : 'RESET PASSWORD LINK HAS BEEN RESEND',
    desc     : 'Your reset password link has been resend. Kindly check your email to reset your password',
    btnText  : '01cf90c98',
    nextLink : '/affiliate-dashboard/login'
  }
}